.section-margin {
  margin-left: 20px;
}

.mgt-light {
  background-color: inherit !important;
}

.mgt-dark {
  background-color: inherit !important;
}

.flex.row {
  display: flex;
  flex-direction: row;
  margin-right: 0.625rem;
}

.flex.column {
  display: flex;
  flex-direction: column;
}

.flex.row > .fui-Card {
  height: 280px;
  width: 330px;
  margin-right: 0.625rem;
  justify-content: space-between;
}

.fluid.button {
  max-width: 100%;
  width: 100%;
}

.profile-card {
  height: max-content;
  margin: 0.5em 0;
  width: 340px;
  min-height: 88px;
}
